import React, { useEffect, useRef } from 'react'
import { Checkbox, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { collect } from 'react-recollect';
import { appStore } from '../store/store';

const ExcludeCampaignPatientsCheckbox = ({ store }) => {
  const isInitialRender = useRef(true);
  
  const handleChange = (event) => {
    store.filters.excludeCampaignPatients = event.target.checked
  }

  useEffect(() => {
    // Check if it's the initial render (to prevent calling it for the second time)
    if (!isInitialRender.current) appStore.loadPatients();
    else isInitialRender.current = false;
  }, [store.filters.excludeCampaignPatients])
  
  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={store.filters.excludeCampaignPatients} onChange={handleChange} />}
        label={
          <Typography sx={{ fontSize: '14px', color: 'var(--color-primary)' }}>
            Exclude campaign patients
          </Typography>
        }
      />
    </FormGroup>
  )
}

export default collect(ExcludeCampaignPatientsCheckbox)